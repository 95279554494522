.navigation-bar-container {
    width: 100%;
    margin-top: 25px;
}

.navigation-bar-container nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    margin-top: 32px;
    margin-left: 210px;
    margin-right: 210px;
    margin-bottom: 32px;
}

.nav-links {
    display: flex;
    gap: 16px;
    align-items: center;
}

.logo-container {
    font-size: 24px;
    font-weight: 700;
    color: #0357D7;
    letter-spacing: -1px;
    margin-right: 22px;
    line-height: normal;
}

.nav-links a {
    color: #4b5563;
    text-decoration: none;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: -0.5px;
    padding: 6px 16px;
    border-radius: 20px;
    transition: background-color 0.2s ease-in-out;
}

.nav-links a:hover {
    color: #1f2937;
    background-color: rgba(0, 0, 0, 0.04);
}

.nav-links a:active {
    background-color: rgba(0, 0, 0, 0.08);
}

.nav-links a.active {
    font-weight: 800;
    background-color: transparent; /* No background for active state */
}

.nav-links a.active:hover {
    background-color: rgba(0, 0, 0, 0.04); /* Only show background on hover, even for active links */
}

.logout-section {
    display: flex;
    align-items: center;
    gap: 7.5px;
    font-weight: 400;
    padding: 6px 16px;
    border-radius: 20px;
    transition: background-color 0.2s ease-in-out;
}

.logout-section:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.logout-section:active {
    background-color: rgba(0, 0, 0, 0.08);
}