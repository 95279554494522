.list-item-container {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
    padding-left: 12px; /* Added left padding for better alignment */
}

.item-text-container {
    margin-left: 12px; /* Increased from 8px to 12px for more spacing */
}

.item-name {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -1px;
}

.item-description {
    color: #565656;
    font-family: "Wanted Sans";
    font-size: 14px;
    line-height: 16px;
    white-space: normal;
    word-wrap: break-word;
    margin-top: 3.5px;
    margin-bottom: 5px;
    max-width: 210px; /* Add maximum width to prevent text from overflowing */
}

.description-stack {
    display: flex;
    flex-direction: column;
    gap: 4px; 
}

.description-stack div:first-child {
    color: #565656;
}

.description-stack div:last-child {
    font-weight: 400;
}