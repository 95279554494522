.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  box-sizing: border-box;
}
  
.image-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  /* Center the image container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  width: 100%;
  height: 100%; /* Increased from 50px to fill container */
  object-fit: cover;
}

.user-info-container {
  text-align: center;
  width: 100%;
  /* Add margin to create space between elements */
  margin: 8px 0;
}

.name-container {
  color: #000;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Wanted Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* Increased from 16px for better spacing */
  letter-spacing: -1px;
  margin-bottom: 8px;
}

.username-container {
  color: #565656;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* Increased from 16px for better spacing */
  letter-spacing: -1px;
}