.login-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1300px;
    max-height: 1300px;
    margin: 0 auto;
}

.title-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.title-text {
    font-size: 52px;
    font-weight: 680;
    letter-spacing: -0.05em;
    color: #0357D7
}

.title-description-text {
    font-size: 18px;
    margin-top: 6px;
}

.login-input-container {
    margin-top: 35px;
    width: 444px;
}

.login-failed-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 444px;
    margin: 5px;
    font-size: 13px; 
}

.login-assistance-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 444px;
    margin: 5px;
    font-size: 13px;
}

.forgot-password-input-container a {
    text-decoration: none;
}

.login-button-container {
    margin-top: 8px;
}

.divider-container {
    width: 444px;
    margin: 20px 8px;
    display: flex;
    align-items: center;
    text-align: center;
}

.divider-text {
    padding: 0 10px;
    color: #666;
    font-size: 14px;
    font-weight: 700;
}

.signup-button-container {
    width: 444px;
    margin: 8px;
}

.signup-button:hover {
    background-color: #f5f5f5;
}