.dialog-container :global(.MuiBackdrop-root) {
    background-color: rgba(0, 0, 0, 0.15);
}

.dialog-paper {
    width: 500px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    box-shadow: none;
    padding: 32px;
    border: 1px solid #E6E8E9;
    background: #FFF;
}

.header-container {
    margin-bottom: 5px;  /* Add space between header and content */
}

.dialog-description {
    color: #565656;  /* Gray color for the text */
    font-family: "Wanted Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: -16px;  /* Reduce space between title and description */
    padding-left: 24px;  /* Match the DialogTitle padding */
}


.close-button {
    position: absolute !important;
    top: 15px;
    right: 15px;
    z-index: 1;
    background-color: #565656 !important;
    padding: 1px !important;
}

.close-button svg {
    width: 16px;
    height: 16px;
}

.close-button-icon {
    color: white;
}

.close-button:hover {
    background-color: rgba(0, 0, 0, 0.9) !important;
}

.input-field {
    margin-bottom: 18px !important;
}

.input-field :global(.MuiOutlinedInput-root) {
    border-radius: 4px;
    background: #FFF;
}

.input-field :global(.MuiOutlinedInput-input) {
    padding: 12px 16px;
    font-family: "Wanted Sans";
    font-size: 14px;
    color: #000;
}

.chip {
    background: #F6F6F6 !important;
    border-radius: 4px !important;
    padding: 4px 8px !important;
    height: auto !important;
    font-family: "Wanted Sans" !important;
    font-size: 14px !important;
    color: #000 !important;
}

.chip :global(.MuiChip-deleteIcon) {
    color: #565656;
    margin-left: 4px;
    width: 16px;
    height: 16px;
}