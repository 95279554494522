.header {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -1px;
    margin-left: 12px; /* Adjusted from 15px to 12px to match list items */
    margin-bottom: 3px;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* Add padding to content for better spacing */
    padding: 0 !important;
}

.list {
    max-height: 170px;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    transition: max-height 0.3s ease;
    padding-right: 4px;
    /* Ensure list doesn't cause horizontal scrolling */
    width: 100%;
    box-sizing: border-box;
}

/* Webkit scrollbar styling */
.list::-webkit-scrollbar {
    width: 6px;
}

.list::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
    margin: 4px 0;
}

.list::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
    min-height: 40px;
}

.list:hover::-webkit-scrollbar-track {
    background: #FFF;
}

.list:hover::-webkit-scrollbar-thumb {
    background: #E6E8E9;
}

/* Firefox scrollbar styling */
.list {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.list:hover {
    scrollbar-color: #E6E8E9 #FFF;
}

.list-collapsed {
    margin-top: 0px;
    max-height: 155px;
}

.list-expanded {
    max-height: 200px;
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 8px;
    padding-left: 0; /* Reset any padding here */
}

.action-button:hover {
    color: #111827;
}

.none-text {
    color: #565656;
    font-family: 'Wanted Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;
    padding-left: 12px;
}

.add-member-container {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
    padding-left: 12px;
  }