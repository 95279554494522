.group-list-container {
    display: flex;
    width: 100%;
    max-width: 1480px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex-shrink: 0;
    height: 100vh;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
        display: none;
    }
    
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.header-container {
    display: flex;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #E6E8E9;
    background: #FFF;
}

.header {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-self: stretch;
}

.description {
    color: #565656;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Wanted Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;
    gap: 16px;  
}
