.landing-page {
    display: flex;
    min-height: 100vh;
}

.left-section {
    flex: 1;
}

.right-section {
    flex: 1;
}