.signup-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1300px;
    max-height: 1300px;
    margin: 0 auto;
}

.title-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 444px;
    margin-bottom: 2px;
}

.title-text {
    font-size: 48px;
    font-weight: 680;
    letter-spacing: -0.05em;
    color: #0357D7;
    margin-bottom: 9px;
}

.title-description-text {
    font-size: 18px;
}

.signup-input-container {
    width: 100%;
    max-width: 444px;
}

.terms-and-conditions-container {
    margin-bottom: 5px;
    width: 100%;
    max-width: 444px;
}
.create-account-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 440px;
}