.app-preview-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1300px;
    max-height: 1300px;
    margin: 0 auto;
}
  
.image-container {
    position: relative;  /* Added for overlay positioning */
    width: 95%;
    height: 96.1%;
    overflow: hidden;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1250px;
    max-height: 1250px;
}
  
.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 500ms;
}

/* New overlay styles */
.overlay-container {
    position: absolute;
    bottom: 0;  /* Start from bottom */
    left: 0;
    right: 0;
    padding: 24px;
    background: linear-gradient(
        transparent,
        rgba(0, 0, 0, 0.4) 30%,
        rgba(0, 0, 0, 0.7)
    );
    color: white;
    padding-bottom: 55px; /* Add extra padding at bottom */
    padding-left: 50px;
}

.overlay-content {
    position: relative;
    bottom: 48px;  /* Move text content up */
    padding: 0 24px;  /* Side padding for text */
}

.upload-info {
    font-size: 0.875rem;
    opacity: 0.8;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.title-text {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 8px;
    line-height: 1.3;
}

.subtitle-text {
    font-size: 0.875rem;
    opacity: 0.9;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .overlay-container {
        padding-bottom: 48px;
    }
    
    .overlay-content {
        bottom: 32px;
        padding: 0 16px;
    }
}