.home-page-container {
  margin-left: 210px;
  margin-right: 210px;
  display: flex;
  gap: 32px;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: calc(100vw - 440px);
  box-sizing: border-box;
  padding-bottom: 40px;
}

.user-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  height: fit-content;
  min-width: 0;
  position: sticky;
  top: 0;
}

.user-content-container {
  width: calc(100% - 320px); /* Keep this calculation */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 0;
  padding-bottom: 40px;
  /* Remove any right margin or padding that might be limiting width */
  padding-right: 0;
  margin-right: 0;
}

.content {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

.side-list-container {
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.user-profile-card-container {
  width: 100%;
  margin-bottom: 16px;
}

/* Form container styles to prevent overflow */
.form-container {
width: 100%;
box-sizing: border-box;
max-width: 100%;
overflow-x: hidden;
}

/* For medium-sized screens */
@media screen and (max-width: 1400px) {
.home-page-container {
  margin-left: 120px;
  margin-right: 120px;
  max-width: calc(100vw - 240px);
}
}

/* For smaller screens */
@media screen and (max-width: 1200px) {
.home-page-container {
  margin-left: 60px;
  margin-right: 60px;
  max-width: calc(100vw - 120px);
}
}

/* For mobile screens */
@media screen and (max-width: 768px) {
.home-page-container {
  margin-left: 20px;
  margin-right: 20px;
  max-width: calc(100vw - 40px);
  flex-direction: column;
}

.user-container {
  width: 100%;
  position: relative;
}

.user-content-container {
  width: 100%;
}
}