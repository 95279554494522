.reset-password-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1300px;
    max-height: 1300px;
    margin: 0 auto;
}

.title-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 444px;
}

.title-text {
    font-size: 48px;
    font-weight: 680;
    letter-spacing: -0.05em;
    color: #0357D7;
}

.title-description-text {
    font-size: 18px;
    margin-top: 6px;
}

.reset-password-input-container {
    margin-top: 35px;
    width: 100%;
    max-width: 444px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reset-password-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 444px;
}